import rec from "./assets/image/rec.png";
import title from "./assets/image/title.png";
import logo from "./assets/image/logo.png";
import tg from "./assets/image/tg.png";
import tw from "./assets/image/tw.png";
import pinksale from "./assets/image/pinksale.png";
import dexview from "./assets/image/dexview.webp";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="rec">
        <img className="rec-img" src={rec} alt="" />
        <div className="rec-content">
          <div className="rec-title">
            <img src={title} alt="" />
          </div>
          <p className="rec-desc">
            A collection of unique digital artworks represented as NFTs. We
            offers a marketplace where collectors can buy and trade these
            exclusive digital assets, providing a platform for artists to
            showcase and sell their creations in the form of non-fungible
            tokens.
            <br />
            <br />
            CA: 0x88804ac32e36BBa3fA9efB951F96Bc9BC694Fef0
            <br />
            Contact: azuki20owner@gmail.com
          </p>
        </div>
      </div>
      <div className="logo">
        <img src={logo} alt="" />
      </div>
      <div className="socials">
        <a
          className="btn-wrapper"
          href="https://t.me/azuki2eth"
          target="_blank"
          rel="noopener noreferrer">
          <span className="btn">
            <img src={tg} alt="" />
            Telegram
          </span>
        </a>
        <a
          className="btn-wrapper"
          href="https://twitter.com/azuki2eth"
          target="_blank"
          rel="noopener noreferrer">
          <span className="btn">
            <img src={tw} alt="" />
            Twitter
          </span>
        </a>
      </div>
      <div className="support">
        <h5>Support by:</h5>
        <div className="support-list">
          <a
            className="support-img"
            href="https://www.pinksale.finance/launchpad/0x96174E35D91D2F114BEB8A44FFa1599bf7be8B39?chain=ETH"
            target="_blank"
            rel="noopener noreferrer">
            <img src={pinksale} alt="" />
          </a>
          <a
            className="support-img"
            href="https://www.dexview.com/"
            target="_blank"
            rel="noopener noreferrer">
            <img src={dexview} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
